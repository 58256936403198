import axios from "axios";
import { BASE_API_URL, accessToken } from "src/config";
import { errorMessage } from "src/utils/apiCalls";
import { reduxErrorMessage } from "src/utils/helper";
import { getRequestHeaders } from "src/utils/FinmoNetworkUtils";

export const FETCH_ORG = "FETCH_ORG";
export const SET_ORG = "SET_ORG";
export const FAILED_ORG_FETCH = "FAILED_ORG_FETCH";

export const fetchOrganization = () => ({
  type: FETCH_ORG,
});

export const setOrganization = (payload) => ({
  type: SET_ORG,
  payload,
});

export const setError = (payload) => ({
  type: FAILED_ORG_FETCH,
  payload,
});

export const getAllOrganization = (limitLink = "") => async (dispatch) => {
  dispatch(fetchOrganization());
  try {
    const requestHeaders = await getRequestHeaders();
    let API_URL = `${BASE_API_URL}/backoffice/organization`;
    if (limitLink) {
      API_URL = `${BASE_API_URL}/backoffice${limitLink}`;
    }
    const response = await axios.get(`${API_URL}`, { headers: requestHeaders });
    const data = response.data;
    dispatch(setOrganization(data));
  } catch (error) {
    const message = reduxErrorMessage(error);
    dispatch(setError(message));
  }
};

export const getOrgSpecific = async (org_id) => {
  try {
    const requestHeaders = await getRequestHeaders();
    const response = await axios.get(
      `${BASE_API_URL}/backoffice/organization/${org_id}/user`,
      { headers: requestHeaders }
    );
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};
