import { useContext } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Box, Drawer, Hidden } from "@mui/material";
import { experimentalStyled } from "@mui/material/styles";
// import SidebarTopSection from './SidebarTopSection';
import { SidebarContext } from "src/contexts/SidebarContext";
import SidebarMenu from "./SidebarMenu";
import Logo from "src/assests/images/Finmo.png";

const SidebarWrapper = experimentalStyled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        height: 100%;
        
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            position: fixed;
            z-index: 10;
        }
`
);

const TopSection = experimentalStyled(Box)(
  ({ theme }) => `
        margin: ${theme.spacing(2)};
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();

  return (
    <>
      <Hidden lgDown>
        <SidebarWrapper>
          <Scrollbars autoHide>
            <TopSection>
              <Box sx={{ ml: 1, mt: 4 }}>
                <img style={{ width: "60%" }} src={Logo} alt="logo" />
              </Box>
            </TopSection>
            <SidebarMenu />
          </Scrollbars>
        </SidebarWrapper>
      </Hidden>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggle}
          onClose={closeSidebar}
          variant="temporary"
          elevation={9}
        >
          <SidebarWrapper>
            <Scrollbars autoHide>
              <TopSection>
                <Box sx={{ ml: 1, mt: 4 }}>
                  <img
                    style={{ width: "80%" }}
                    src={Logo}
                    alt="logo"
                    width={200}
                  />
                </Box>
              </TopSection>
              <SidebarMenu />
            </Scrollbars>
          </SidebarWrapper>
        </Drawer>
      </Hidden>
    </>
  );
}

export default Sidebar;
