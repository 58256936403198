import PropTypes from "prop-types";
import { experimentalStyled } from "@mui/material/styles";
import { Box, Alert } from "@mui/material";

import Sidebar from "./Sidebar";
import Header from "./Header";
import { useSelector } from "src/store";
const MainWrapper = experimentalStyled(Box)(
  ({ theme }) => `
        flex: 1 1 auto;
        display: flex;
        height: 100%;
        
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            padding-left: ${theme.sidebar.width};
        }
`
);

const MainContent = experimentalStyled(Box)(
  ({ theme }) => `
        margin-top: ${theme.header.height};
        flex: 1 1 auto;
        overflow: auto;
`
);
const AlertBox = experimentalStyled(Alert)(
  ({ theme }) => `
        margin-top: 2px;
        border-radius:0;
        text-align:center;
        justify-content: center;
        overflow: auto;
`
);
const AccentSidebarLayout = ({ children }) => {
  return (
    <>
      <Sidebar />
      <MainWrapper>
        <Header />
        <MainContent>{children}</MainContent>
      </MainWrapper>
    </>
  );
};

AccentSidebarLayout.propTypes = {
  children: PropTypes.node,
};

export default AccentSidebarLayout;
