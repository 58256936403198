import PropTypes from "prop-types";
import { experimentalStyled } from "@mui/material/styles";

const LabelWrapper = experimentalStyled("span")(
  ({ theme }) => `
      background-color: ${theme.colors.alpha.black[5]};
      padding: ${theme.spacing(0.5, 1)};
      font-size: 12px;
      border-radius: ${theme.general.borderRadius};
      display: inline-flex;
      align-items: center;
      justify-content: center;
      max-height: ${theme.spacing(3)};
      
      &.MuiLabel {
        &-primary {
          background-color: ${theme.colors.primary.lighter};
          color: ${theme.palette.primary.main}
        }

        &-primary-outlined {
          background-color: ${theme.colors.primary.lighter};
          color: ${theme.palette.primary.main};
          border:1px solid ${theme.colors.primary.main};
          border-radius:5px;
        }
        
        &-secondary {
          background-color: ${theme.colors.secondary.lighter};
          color: ${theme.palette.secondary.main}
        }

        &-secondary-outlined {
          background-color: ${theme.colors.secondary.lighter};
          color: ${theme.palette.secondary.main};
          border:1px solid ${theme.colors.secondary.main};
          border-radius:5px;
        }
        
        &-success {
          background-color: ${theme.colors.success.lighter};
          color: ${theme.palette.success.main}
        }

        &-success-outlined {
          background-color: ${theme.colors.success.lighter};
          color: ${theme.palette.success.main};
          border:1px solid ${theme.colors.success.main};
          border-radius:5px;
        }
        
        &-warning {
          background-color: ${theme.colors.warning.lighter};
          color: ${theme.palette.warning.main}
        }

        &-warning-outlined {
          background-color: ${theme.colors.warning.lighter};
          color: ${theme.palette.warning.main};
          border:1px solid ${theme.colors.warning.main};
          border-radius:5px;
        }
              
        &-error {
          background-color: ${theme.colors.error.lighter};
          color: ${theme.palette.error.main}
        }

        &-error-outlined {
          background-color: ${theme.colors.error.lighter};
          color: ${theme.palette.error.main};
          border:1px solid ${theme.colors.error.main};
          border-radius:5px;
        }
        
        &-info {
          background-color: ${theme.colors.info.lighter};
          color: ${theme.palette.info.main}
        }

        &-info-outlined {
          background-color: ${theme.colors.info.lighter};
          color: ${theme.palette.info.main};
          border:1px solid ${theme.colors.info.main};
          border-radius:5px;
        }
        }
      }
`
);

const checkStatus = (text) => {
  switch (true) {
    case text.startsWith("2"): {
      return "success";
    }
    case text.startsWith("4"): {
      return "warning";
    }
    case text.startsWith("5"): {
      return "error";
    }
  }

  switch (text.toUpperCase()) {
    case "APPROVED":
    case "ACCEPTED":
    case "COMPLETED":
    case "ACTIVE":
    case "PAID":
    case "CLEAR":
    case "Refund_Active":
    case "POST":
    case "SIGNED":
    case "true":
    case "IN_REVIEW":
    case "WHITELISTED":
    case "BUY":
    case "TRUE": {
      return "success";
    }

    case "CUST_SUBMITTED":
    case "ACTIVATED":
    case "INITIATED":
    case "CREATED":
    case "Refund_Closed": {
      return "primary";
    }

    case "CHURNED":
    case "DEACTIVATED":
    case "REJECTED":
    case "DELETED":
    case "FAILED":
    case "false":
    case "FALSE":
    case "DELETE":
    case "UNSIGNED":
    case "ESCALATED":
    case "FLAGGED":
    case "DISMISSED":
    case "RISK_ACCEPTED":
    case "HIGH":
    case "SELL":
    case "INACTIVE": {
      return "error";
    }

    case "PENDING":
    case "CANCELLED":
    case "SUSPECTED":
    case "CAUTION":
    case "PUT":
    case "PATCH":
    case "RESUBMITTED":
    case "UNASSIGNED":
    case "LOW":
    case "APPROVAL PENDING": {
      return "warning";
    }

    case "WAIVED_OFF":
    case "TO_BE_RESUBMITTED":
    case "GET":
    case "OPEN":
    case "REQUESTED": {
      return "info";
    }

    default: {
      return "secondary";
    }
  }
};

const getClassName = (text, variant, color) => {
  let class_name = "MuiLabel-";
  if (text === null) {
    class_name += color;
  } else {
    const status = checkStatus(text.trim());
    class_name += status;
  }
  if (variant === "outlined") {
    class_name += `-${variant}`;
  }

  return class_name;
};

const Label = ({
  className = "",
  color = "secondary",
  children,
  text = null,
  variant = "contained",
  ...rest
}) => {
  return (
    <LabelWrapper
      className={getClassName(text, variant, color)}
      style={{ fontSize: "12px" }}
      {...rest}
    >
      {children || text?.toUpperCase()}
    </LabelWrapper>
  );
};

Label.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "error",
    "warning",
    "success",
    "info",
  ]),
};

export default Label;
