import { ListSubheader, List } from "@mui/material";
import { useLocation, matchPath } from "react-router-dom";
import SidebarMenuItem from "./item";
import menuItems from "./items";
import { experimentalStyled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import useAuth from "src/hooks/useAuth";

const MenuWrapper = experimentalStyled(List)(
  ({ theme }) => `
    margin-bottom: ${theme.spacing(1)};
    padding:0;
    .MuiListSubheader-root {
      text-transform: uppercase;
      font-size: ${theme.typography.pxToRem(13)};
      color: ${theme.sidebar.menuItemHeadingColor};
      padding: ${theme.spacing(1, 3)};
      line-height: 1.5;
    }
`
);
const SubMenuWrapper = experimentalStyled(List)(
  ({ theme }) => `
    &.MuiList-root {
      padding:0;
      .MuiListItem-root {
        padding: 1px;
        padding-left: ${theme.spacing(0)};
        padding-right: ${theme.spacing(0)};

        .MuiButton-label {
          display: flex;
          position: relative;
          font-size:12px;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(2)};

            .MuiBadge-standard {
              background: ${theme.colors.primary.main};
              font-size: ${theme.typography.pxToRem(12)};
              color: ${theme.palette.primary.contrastText};
            }
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.sidebar.menuItemColor};
          background-color: ${theme.sidebar.menuItemBg};
          width: 100%;
          border-radius: 0;
          justify-content: flex-start;
          font-size: ${theme.typography.pxToRem(12)};
          padding: ${theme.spacing(2)};
          padding-top: ${theme.spacing(1)};
          padding-bottom: ${theme.spacing(1)};

          &:after {
            content: '';
            position: absolute;
            height: 100%;
            right: 0;
            top: 0;
            width: 0;
            opacity: 0;
            transition: ${theme.transitions.create(["opacity", "width"])};
            background: ${theme.sidebar.menuItemColorActive};
            border-top-left-radius: ${theme.general.borderRadius};
            border-bottom-left-radius: ${theme.general.borderRadius};
          }
    
          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(["color"])};
            font-size:10px;
            .MuiSvgIcon-root {
              transition: none;
            }
          }

          .MuiButton-startIcon {
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1.5)};
            color: ${theme.sidebar.menuItemIconColor};
          }
          
          .MuiButton-endIcon {
            margin-left: auto;
            margin-right:${theme.spacing(0.5)};
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.Mui-active,
          &:hover {
            background-color: ${theme.sidebar.menuItemBgActive};
            color: ${theme.sidebar.menuItemColorActive};
            .MuiButton-startIcon,
            .MuiButton-endIcon {
                color: ${theme.sidebar.menuItemIconColorActive};
            }
          }

          &.Mui-active {
            &:after {
              width: 5px;
              opacity: 1;
            }
          }
        }

        &.Mui-children {
          flex-direction: column;
          line-height: 1;

          .MuiButton-root.Mui-active {
            &:after {
              opacity: 0;
            }
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px;
            padding-left: ${theme.spacing(0)};
            padding-right: ${theme.spacing(0)};

            .MuiButton-root {
              font-size: ${theme.typography.pxToRem(12)};
              padding: ${theme.spacing(0.5, 2, 0.5, 2)};
              font-weight: normal;

              &:before {
                content: '';
                background: ${theme.sidebar.menuItemIconColor};
                opacity: .8;
                transition: ${theme.transitions.create(["background"])};
                width: 6px;
                height: 6px;
                border-radius: 20px;
                margin-right: ${theme.spacing(2.9)};
              }

              &.Mui-active,
              &:hover {
                background-color: ${theme.sidebar.menuItemBg};

                &:before {
                  background: ${theme.sidebar.menuItemIconColorActive};
                }

                &:after {
                  opacity: 0;
                }
              }
            }
          }
        }
      }
    }
`
);

function renderSidebarMenuItems({ items, path }) {
  return (
    <SubMenuWrapper>
      {items.reduce((ev, item) => reduceChildRoutes({ ev, item, path }), [])}
    </SubMenuWrapper>
  );
}

function reduceChildRoutes({ ev, path, item }) {
  const key = item.name;

  if (item.items) {
    const open = matchPath(path, {
      path: item.link,
      exact: false,
    });

    ev.push(
      <SidebarMenuItem
        key={key}
        open={Boolean(open)}
        name={item.name}
        icon={item.icon}
        badge={item.badge}
      >
        {renderSidebarMenuItems({
          path,
          items: item.items,
        })}
      </SidebarMenuItem>
    );
  } else {
    ev.push(
      <SidebarMenuItem
        key={key}
        name={item.name}
        link={item.link}
        badge={item.badge}
        icon={item.icon}
      />
    );
  }

  return ev;
}

function filterMenuTab(items, user_type) {
  return items.filter((item) => {
    if (item.user_allowed.includes(user_type)) {
      return item;
    }
  });
}

function SidebarMenu() {
  const location = useLocation();
  const { t } = useTranslation();
  const { user } = useAuth();
  return (
    <>
      {menuItems.map((section) => (
        <MenuWrapper
          key={section.heading}
          subheader={
            <ListSubheader disableSticky>{t(section.heading)}</ListSubheader>
          }
        >
          {renderSidebarMenuItems({
            items: user.is_admin
              ? filterMenuTab(section.items, user.user_type)
              : filterMenuTab(
                  section.items.filter((item) => {
                    if (item.name !== "Admin Accounts") {
                      return item;
                    }
                  }),
                  user.user_type
                ),
            path: location.pathname,
          })}
        </MenuWrapper>
      ))}
    </>
  );
}

export default SidebarMenu;
