import { reduxErrorMessage } from 'src/utils/helper';
import { getAllAccounts } from 'src/utils/FinmoNetworkUtils';

export const FETCH_ORG_ACCOUNTS = 'FETCH_ORG_ACCOUNTS';
export const SET_ORG_ACCOUNTS = 'SET_ORG_ACCOUNTS';
export const FAILED_ORG_ACCOUNTS_FETCH = 'FAILED_ORG_ACCOUNTS_FETCH';

export const fetchOrgAccounts = () => ({
  type: FETCH_ORG_ACCOUNTS
});

export const setOrgAccounts = (payload) => ({
  type: SET_ORG_ACCOUNTS,
  payload
});

export const setError = (payload) => ({
  type: FAILED_ORG_ACCOUNTS_FETCH,
  payload
});

export const getAllOrgAccounts = () => async (dispatch) => {
  dispatch(fetchOrgAccounts());
  try {
    const response = await getAllAccounts();
    dispatch(setOrgAccounts(response));
    return response;
  } catch (error) {
    const { message } = reduxErrorMessage(error);
    dispatch(setError(message));
  }
};
