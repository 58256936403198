import React, { useState, useEffect } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import ReactGA from "react-ga";
import routes, { renderRoutes } from "./router";
import { SnackbarProvider } from "notistack";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import ScrollToTop from "./utils/ScrollToTop";
import "./App.css";
import { AuthProvider } from "./contexts/JWTAuthContext";
import ThemeProvider from "./theme/ThemeProvider";
import { CssBaseline } from "@mui/material";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const history = createBrowserHistory();

Sentry.init({
  dsn:
    "https://b63449a578374b8fa8ac85be9f3d1399@o4504161939357696.ingest.sentry.io/4504161941127168",
  integrations: [new BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
  tracesSampleRate: 1.0,
});

function App() {
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider
          maxSnack={6}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Router history={history}>
            <ScrollToTop />
            <CssBaseline />
            <AuthProvider>{renderRoutes(routes)}</AuthProvider>
          </Router>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
