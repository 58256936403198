import {
    FETCH_ORG_ACCOUNTS,
    SET_ORG_ACCOUNTS,
    FAILED_ORG_ACCOUNTS_FETCH
  } from '../actions/orgAccountsAction';
  
  const initalState = {
    fetching: false,
    orgAccounts: {},
    error: null
  };
  
  export const orgAccountsReducer = (
    state = initalState,
    { type, payload }
  ) => {
    switch (type) {
      case FETCH_ORG_ACCOUNTS:
        return { fetching: true, orgAccounts: {}, error: null };
      case SET_ORG_ACCOUNTS:
        return {
          ...state,
          fetching: false,
          orgAccounts: payload,
          error: null
        };
      case FAILED_ORG_ACCOUNTS_FETCH:
        return {
          ...state,
          fetching: false,
          orgAccounts: {},
          error: payload
        };
  
      default:
        return state;
    }
  };
  