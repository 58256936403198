import { createContext, useEffect, useReducer } from "react";
import jwtDecode from "jwt-decode";
import SuspenseLoader from "src/components/SuspenseLoader";
import axios from "axios";
import { BASE_API_URL, WEB_GTWY_API_URL } from "src/config";
import { apiErrorMessage, toastNotification } from "src/utils/helper";
import {
  fetchAllOrganization,
  getOrgAccDetails,
  fetchAllPartners,
  logoutSession,
} from "src/utils/FinmoNetworkUtils";
import { useHistory } from "react-router";
import { ORG_FILTER_LIMIT } from "src/utils/globalStaticData";

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  customData: null,
  otp_login_user: null,
  authData: null,
  mode: "production",
};

const isValidToken = (access_token) => {
  if (!access_token) {
    return false;
  }

  const decoded = jwtDecode(access_token);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (access_token) => {
  if (access_token) {
    localStorage.setItem("access_token", access_token);
    axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
  } else {
    localStorage.removeItem("access_token");
    delete axios.defaults.headers.common.Authorization;
  }
};

const setSessionNew = (key, value) => {
  if (value) {
    localStorage.setItem(key, value);
    if (key == "access_token") {
      axios.defaults.headers.common.Authorization = `Bearer ${value}`;
    }
  } else {
    localStorage.removeItem(key);
    if (key == "access_token") {
      delete axios.defaults.headers.common.Authorization;
    }
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INITIALISE": {
      const { isAuthenticated, user, customData } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
        customData,
      };
    }
    case "LOGIN": {
      const { user, authData, customData } = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        otp_login_user: null,
        authData: authData || {},
        user,
        customData,
      };
    }
    case "OTP_LOGIN": {
      const { otp_login_user } = action.payload;
      return {
        ...state,
        otp_login_user,
      };
    }
    case "VERIFY_EMAIL_LOGIN": {
      const { user } = action.payload;
      return {
        ...state,
        user,
      };
    }
    case "LOGIN_FAILED": {
      const { message, user } = action.payload;

      return {
        ...state,
        login_err: message,
        user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        otp_login_user: null,
        authData: null,
        customData: null,
      };
    }
    case "REGISTER": {
      const { message } = action.payload;

      return {
        ...state,
        register_suc: message,
      };
    }
    case "REGISTER_FAILED": {
      const { message } = action.payload;

      return {
        ...state,
        register_err: message,
      };
    }
    case "RESET": {
      return {
        ...state,
        register_err: null,
        login_err: null,
        register_suc: null,
      };
    }
    case "UPDATE_MODE": {
      const { mode } = action.payload;
      return {
        ...state,
        mode: mode,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: "JWT",
  otp_login: () => Promise.resolve(),
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const otp_login = async (email, password) => {
    try {
      const response = await axios.post(`${WEB_GTWY_API_URL}/login`, {
        email,
        password,
      });
      const { success, data } = response.data;
      if (success) {
        if (data.is_email_verified === false) {
          dispatch({
            type: "VERIFY_EMAIL_LOGIN",
            payload: {
              user: { ...data },
            },
          });
          history.push("/email-verification");
        } else {
          // setSessionNew("access_token", data.access_token);
          setSessionNew(
            "password_reset_required",
            data.password_reset_required
          );
          dispatch({
            type: "OTP_LOGIN",
            payload: {
              otp_login_user: { ...data },
            },
          });
          toastNotification("success", "User LogedIn Successfully", 1500);
        }
      }
    } catch (error) {
      toastNotification("error", apiErrorMessage(error), 2000);
    }
  };

  const login = async (sessionId, otp) => {
    try {
      const response = await axios.post(
        `${WEB_GTWY_API_URL}/verifyLoginOtp/${sessionId}/${otp}`
      );
      const { success, data } = response.data;
      if (success) {
        setSessionNew("access_token", data.access_token);
        setSessionNew("session_id", data.session_id);
        const orgData = await getOrgAccDetails();
        if (orgData.success) {
          dispatch({
            type: "LOGIN",
            payload: {
              user: { ...orgData.data },
              authData: { ...data },
            },
          });
        }
        toastNotification("success", "User LogedIn Successfully", 1500);
        try {
          let custom_data = {};
          const res = await fetchAllOrganization([
            { filter_key: "limit", filter_value: ORG_FILTER_LIMIT },
            {
              filter_key: "organization_fields",
              filter_value:
                "org_id,name,created_at,tag_list,is_partner_privelege_enabled,partner_id",
            },
            { filter_key: "is_active", filter_value: true },
            { filter_key: "kyb_fields", filter_value: "status" },
          ]);
          let all_org_name_and_id = {};
          let gca_enabled_org_list = [];
          res.data.forEach((org) => {
            all_org_name_and_id[org.organization.org_id] =
              org.organization.name;
            if (org.organization?.is_gca_enabled)
              gca_enabled_org_list.push(org.organization);
          });
          custom_data = {
            ...custom_data,
            all_orgs: res,
            all_org_name_and_id: all_org_name_and_id,
            gca_enabled_org_list,
          };

          try {
            const resp = await fetchAllPartners([
              { filter_key: "limit", filter_value: 10000 },
            ]);
            let all_partner_name_and_id = {};
            resp.data.forEach((pd) => {
              all_partner_name_and_id = {
                ...all_partner_name_and_id,
                [pd.partner_id]: pd.name,
              };
            });

            custom_data = {
              ...custom_data,
              all_partner_name_and_id: all_partner_name_and_id,
            };
          } catch {}

          dispatch({
            type: "LOGIN",
            payload: {
              user: { ...orgData.data },
              customData: custom_data,
              authData: { ...data },
            },
          });
        } catch (err) {
          dispatch({
            type: "LOGIN",
            payload: {
              user: { ...orgData.data },
              customData: { all_orgs_error: err },
              authData: { ...data },
            },
          });
        }
      }
    } catch (error) {
      toastNotification("error", apiErrorMessage(error), 2000);
    }
  };

  const logout = async () => {
    const session_id = localStorage.getItem("session_id");
    try {
      const { success } = await logoutSession(session_id);
      if (success) {
        setSession(null);
        localStorage.clear();
        dispatch({ type: "LOGOUT" });
      }
    } catch (error) {
      console.error(error);
      setSession(null);
      dispatch({ type: "LOGOUT" });
    }
  };

  const { push } = useHistory();

  const register = async (
    first_name,
    last_name,
    email,
    phone_number,
    password
  ) => {
    try {
      const response = await axios.post(`${BASE_API_URL}/register`, {
        first_name,
        last_name,
        email,
        phone_number,
        password,
      });
      const { success, data } = response.data;
      if (success) {
        toastNotification("success", "User Registered Successfully", 1500);
        setSessionNew("access_token", data.access_token);
        // const orgData = await getOrgAccDetails();
        // if (orgData.success) {
        //   dispatch({
        //     type: 'LOGIN',
        //     payload: {
        //       user: { ...orgData.data }
        //     }
        //   });
        // }
        push("/login");
      }
    } catch (error) {
      toastNotification("error", apiErrorMessage(error), 2000);
    }
  };

  const initialise = async () => {
    try {
      const access_token = window.localStorage.getItem("access_token");

      if (access_token) {
        const orgData = await getOrgAccDetails();
        if (orgData.success) {
          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated: true,
              user: { ...orgData.data },
            },
          });

          try {
            let custom_data = {};
            const res = await fetchAllOrganization([
              { filter_key: "limit", filter_value: ORG_FILTER_LIMIT },
              {
                filter_key: "organization_fields",
                filter_value:
                  "org_id,name,created_at,tag_list,is_partner_privelege_enabled,partner_id,is_gca_enabled",
              },
              { filter_key: "is_active", filter_value: true },
              { filter_key: "kyb_fields", filter_value: "status" },
            ]);

            let all_org_name_and_id = {};
            let gca_enabled_org_list = [];
            res.data.forEach((org) => {
              all_org_name_and_id[org.organization.org_id] =
                org.organization.name;
              if (org.organization?.is_gca_enabled)
                gca_enabled_org_list.push(org.organization);
            });

            custom_data = {
              ...custom_data,
              all_orgs: res,
              all_org_name_and_id: all_org_name_and_id,
              gca_enabled_org_list,
            };
            try {
              const resp = await fetchAllPartners([
                { filter_key: "limit", filter_value: 10000 },
              ]);
              let all_partner_name_and_id = {};
              resp.data.forEach((pd) => {
                all_partner_name_and_id = {
                  ...all_partner_name_and_id,
                  [pd.partner_id]: pd.name,
                };
              });

              custom_data = {
                ...custom_data,
                all_partner_name_and_id: all_partner_name_and_id,
              };
            } catch {}
            dispatch({
              type: "INITIALISE",
              payload: {
                isAuthenticated: true,
                user: { ...orgData.data },
                customData: custom_data,
              },
            });
          } catch (err) {
            dispatch({
              type: "INITIALISE",
              payload: {
                isAuthenticated: true,
                user: { ...orgData.data },
                customData: { all_orgs_error: err },
              },
            });
          }
        }
      } else {
        dispatch({
          type: "INITIALISE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "INITIALISE",
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  };

  const toggleMode = () => {
    const { mode } = state;
    const update_mode = mode === "production" ? "sandbox" : "production";
    dispatch({
      type: "UPDATE_MODE",
      payload: {
        mode: update_mode,
      },
    });
  };

  useEffect(() => {
    (async () => {
      await initialise();
    })();
  }, []);

  if (!state.isInitialised) {
    return <SuspenseLoader />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        otp_login,
        login,
        logout,
        initialise,
        register,
        dispatch,
        toggleMode,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
