import { Box, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import parse from "autosuggest-highlight/parse";
import KeyboardCommandKeyIcon from "@mui/icons-material/KeyboardCommandKey";
import match from "autosuggest-highlight/match";
import { InputAdornment, TextField, Autocomplete } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import KeyboardControlKeyIcon from "@mui/icons-material/KeyboardControlKey";

const RouteSearchBox = ({ suggested_route_list, all_route_list }) => {
  const [showSuggestedRouteList, setShowSuggestedRouteList] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [open, setOpen] = useState(true);
  const [menu, setMenu] = useState(false);
  function getOSicon() {
    const platform =
      window.navigator?.userAgentData?.platform || window.navigator.platform;
    const macosPlatforms = [
      "macOS",
      "Macintosh",
      "MacIntel",
      "MacPPC",
      "Mac68K",
    ];
    const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
    const iosPlatforms = ["iPhone", "iPad", "iPod"];

    if (macosPlatforms.includes(platform)) {
      return <KeyboardCommandKeyIcon fontSize="small" />;
    } else if (windowsPlatforms.includes(platform)) {
      return <KeyboardControlKeyIcon fontSize="20px" />;
    } else if (iosPlatforms.includes(platform)) {
      return <KeyboardCommandKeyIcon fontSize="small" />;
    } else return <KeyboardCommandKeyIcon fontSize="small" />;
  }
  const osIcon = getOSicon();
  const history = useHistory();
  let optionList = [];

  const DropDownList = ({ props, option, inputValue }) => {
    const matchLabel = match(option.label, inputValue, {
      insideWords: true,
      requireMatchAll: false,
    });
    const partsLabel = parse(option.label, matchLabel);
    return (
      <Box
        {...props}
        disabled={true}
        component={RouterLink}
        to={option.id === "docs" ? "" : option.link}
        sx={{
          color: "#111633",
          textDecoration: "none",
        }}
        onClick={() => {
          setSearchValue("");
          setShowSuggestedRouteList(true);
          setOpen(false);
          setMenu(false);
        }}
      >
        <Typography>
          {partsLabel.map((part, index) => {
            return (
              <Box
                key={index}
                style={{
                  display: "inline",
                  fontWeight: part.highlight ? 700 : 400,
                  background: part.highlight && "#111633",
                  borderRadius: part.highlight && "2px",
                  color: part.highlight && "#fff",
                  padding: part.highlight && "0px 4px",
                }}
              >
                {part.text}
              </Box>
            );
          })}
        </Typography>
      </Box>
    );
  };
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.metaKey && event.key === "k") {
        setMenu(true);
      } else if (event.ctrlKey && event.key === "k") {
        event.preventDefault();
        setMenu(true);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  return (
    <Box>
      {!menu ? (
        <Box
          display={"flex"}
          position={"relative"}
          onClick={() => {
            setMenu(true);
          }}
        >
          <TextField
            value={""}
            size="small"
            placeholder="Search..."
            sx={{
              width: "165px",
              cursor: "alias",
              marginRight: "10px",
              padding: "20px 10px",
            }}
            // autoComplete="off"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
          />
          <Box
            sx={{
              width: "30px",
              height: "30px",
              borderRadius: "5px",
              background: "#f6f8fb",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "14px",
              position: "absolute",
              right: "25px",
              bottom: "25px",
            }}
          >
            {osIcon} K
          </Box>
        </Box>
      ) : (
        <Autocomplete
          disablePortal
          autoComplete
          autoHighlight
          fullWidth
          open={open || menu}
          onOpen={() => setOpen(true)}
          onClose={() => setMenu(false)}
          noOptionsText={"No result found"}
          filterOptions={(options, { inputValue }) => {
            optionList = options.filter((option) =>
              option.label.toLowerCase().includes(inputValue.toLowerCase())
            );
            return optionList;
          }}
          options={
            showSuggestedRouteList ? suggested_route_list : all_route_list
          }
          sx={{ width: "350px", marginRight: "10px" }}
          groupBy={() => (showSuggestedRouteList ? "Suggested Features" : "")}
          onInputChange={(e) => {
            setSearchValue(e.target.value);
            if (e.target.value) setShowSuggestedRouteList(false);
            else setShowSuggestedRouteList(true);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search..."
              fullWidth
              autoFocus
              size="small"
              value={searchValue}
              sx={{ padding: "none" }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  all_route_list.forEach((element, index) => {
                    if (element.label === event.target.value) {
                      setShowSuggestedRouteList(true);
                      history.push(all_route_list[index].link);
                    } else {
                      if (optionList.length > 0) {
                        setShowSuggestedRouteList(true);
                        history.push(optionList[0].link);
                        optionList = [];
                      }
                    }
                    return;
                  });
                }
              }}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />
          )}
          renderOption={(props, option, { inputValue }) => (
            <DropDownList
              props={props}
              option={option}
              inputValue={inputValue}
            />
          )}
        />
      )}
    </Box>
  );
};

export default RouteSearchBox;
