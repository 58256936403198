import { format } from "date-fns";
import { toast } from "react-toastify";
import Label from "src/components/Label";
import { formatISO } from "date-fns";
import {
  createBOSetting,
  getSingleDoc,
  getOrgControl,
} from "./FinmoNetworkUtils";
import { currency_decimal, decimal_format_list } from "./globalStaticData";
import {
  finmo_country_to_currency,
  finmo_platform_supported_currency_list,
} from "src/utils/countryData";
import numeral from "numeral";
import { useQuery } from "src/hooks/querys";

export const reduxErrorMessage = (error) =>
  error.response && error.response.data.message
    ? error.response.data.message
    : error.message;

export const apiErrorMessage = (error) => {
  if (Array.isArray(error?.response && error?.response?.data?.error?.message)) {
    const message = error?.response && error?.response?.data?.error?.message;
    return message?.join(" , ");
  } else if (error?.response && error?.response?.data?.message) {
    return error?.response?.data?.message;
  } else {
    return error?.response && error?.response?.data?.error
      ? error?.response?.data?.error?.message
      : error?.message;
  }
};

export const limitStr = (str, len, endsWith = "") => {
  if (str.length < len) return str;
  return str.substring(0, len) + endsWith;
};

export const deleteObjProperty = (obj, keys) => {
  const cloneObj = { ...obj };
  keys.map((key) => delete cloneObj[key]);
  return cloneObj;
};

export const getISOformatString = (value) => {
  return `${formatISO(new Date(value), {
    representation: "complete",
  })
    .split(":")
    .slice(0, 2)
    .join(":")}:00Z`;
};
export const printObjProperty = (obj, keys) => {
  const clone = { ...obj };
  Object.keys(clone).forEach((key) => keys.includes(key) || delete clone[key]);
  return clone;
};

export const objToKeyPairArr = (obj) => Object.entries(obj);

export const rmUnderScore = (str) => str.replaceAll("_", " ");

export const isEmptyObj = (obj) =>
  Object.entries(obj).length === 0 && obj.constructor === Object;

export const isObject = (obj) =>
  typeof obj === "object" && obj !== null && !Array.isArray(obj);

export const toastNotification = (type, message, time, position) => {
  toast[type || "error"](message || "", {
    position: position || "bottom-center",
    autoClose: time || 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const matchValue = (val1, val2) => {
  let value1;
  let value2;
  if (val1 && val2) {
    value1 = val1.toLowerCase();
    value2 = val2.toLowerCase();
    if (value1 == value2) {
      return <Label color={"success"}>Matched</Label>;
    }
  }
  return <Label color={"error"}>Unmatched</Label>;
};

export const isObjNull = (obj) =>
  Object.values(obj).every((doc) => {
    if (doc === null) {
      return true;
    }
    return false;
  });

export const pricingMessage =
  "All pricing shown below excludes local taxes and GST";

export const unixFun = (v) => {
  const unix =
    v !== null ? (isNaN(v) ? null : Math.floor(v.getTime() / 1000)) : null;
  return unix;
};

export const pricingModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  height: "auto",
  bgcolor: "#fff",
  borderRadius: "8px",
  border: "0px solid #000",
  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
  p: 4,
};

export const downloadDoc = async (org_id, doc_id) => {
  try {
    const { success, data } = await getSingleDoc(org_id, doc_id);
    if (success) {
      window.open(data.download_url, "_blank");
    }
  } catch (err) {
    toastNotification("error", apiErrorMessage(err), 2000);
  }
};

export const setCustomColumnSettings = (columns, setTableCustomColumnData) => {
  setTableCustomColumnData((prevState) => {
    return prevState.map((prevStateObj) => {
      if (columns?.includes(prevStateObj.columnName)) {
        return { ...prevStateObj, isVisible: true };
      } else {
        return { ...prevStateObj, isVisible: false };
      }
    });
  });
};

export const createCustomColumnSettings = async (
  columns,
  column_setting_key,
  user
) => {
  let data = [];
  columns.forEach((item) => {
    if (item.isVisible) {
      data.push(item.columnName);
    }
  });
  try {
    await createBOSetting(
      {
        environment: "all",
        key: column_setting_key,
        value: data,
      },
      user.bo_user_id
    );
  } catch (err) {}
};

export const convertDateToMonthYear = (date, convert = true) => {
  // MONTH YEAR (JUL 2023)
  if (new Date(date).getTime() > 0) {
    return format(
      new Date(new Date(date).getTime() - (convert ? 86400000 : 0)),
      "MMM yyyy"
    );
  }
};

export const handleAmountChange = (val, curr) => {
  const decimals =
    currency_decimal[curr] === 0
      ? 0
      : currency_decimal[curr]
      ? currency_decimal[curr]
      : 2;
  const arr = val.toString().split(".");
  if (arr.length === 1) return val;
  const int = arr[0],
    max = arr[1].length,
    dec = arr[1].substr(0, decimals > max ? max : decimals);
  const amount = decimals === 0 ? int : [int, ".", dec].join("");
  return amount;
};

export const getOrgEnabledCountryCurrency = async (org_id, env) => {
  try {
    let enabled_country_currency_map = {};
    let enabled_country = [];
    let enabled_currency = [];
    const filters = [
      { filter_key: "environment", filter_value: env },
      { filter_key: "limit", filter_value: 1000 },
    ];

    const data = await Promise.all([
      getOrgControl(
        [...filters, { filter_key: "key_type", filter_value: "COUNTRY" }],

        org_id,
        env
      ),
      getOrgControl(
        [...filters, { filter_key: "key_type", filter_value: "CURRENCY" }],
        org_id,
        env
      ),
    ]);

    const country_data = data?.[0]?.data;
    const currency_data = data?.[1]?.data;

    country_data.forEach((country) => {
      if (
        country?.value !== null &&
        country?.value?.is_enabled &&
        Object.keys(finmo_country_to_currency).includes(country?.key)
      ) {
        let enabled_currency_for_country = [];
        currency_data.forEach((currency) => {
          if (
            currency?.value !== null &&
            currency?.value?.is_enabled &&
            finmo_country_to_currency[country?.key].includes(currency?.key)
          ) {
            enabled_country.push(country?.key);
            enabled_currency.push(currency?.key);
            enabled_currency_for_country.push(currency?.key);
          }
        });
        if (enabled_currency_for_country.length > 0) {
          enabled_country_currency_map[
            country?.key
          ] = enabled_currency_for_country;
        }
      }
    });
    return {
      enabled_country,
      enabled_currency,
      enabled_country_currency_map,
      env,
    };
  } catch (error) {
    toastNotification("error", apiErrorMessage(error), 3000);
  }
};

export const getOrgSettlementEnabledCurrency = async (org_id, env) => {
  try {
    let settlement_enabled_currency_list = [];
    let settlement_payout_enabled_currency_list = [];
    const filters = [
      { filter_key: "environment", filter_value: env },
      { filter_key: "limit", filter_value: 1000 },
    ];

    const data = await Promise.all([
      getOrgControl(
        [...filters, { filter_key: "key_type", filter_value: "SETTLEMENT" }],

        org_id,
        env
      ),
      getOrgControl(
        [
          ...filters,
          { filter_key: "key_type", filter_value: "SETTLEMENT_PAYOUT" },
        ],
        org_id,
        env
      ),
    ]);

    const settlement_data = data?.[0]?.data;
    const settlement_payout_data = data?.[1]?.data;

    settlement_data.forEach((currency) => {
      if (
        currency?.value !== null &&
        currency?.value?.is_enabled &&
        finmo_platform_supported_currency_list.includes(currency?.key)
      ) {
        settlement_enabled_currency_list.push(currency?.key);
      }
    });

    settlement_payout_data.forEach((currency) => {
      if (
        currency?.value !== null &&
        currency?.value?.is_enabled &&
        finmo_platform_supported_currency_list.includes(currency?.key)
      ) {
        settlement_payout_enabled_currency_list.push(currency?.key);
      }
    });

    return {
      settlement_enabled_currency_list,
      settlement_payout_enabled_currency_list,
    };
  } catch (error) {
    toastNotification("error", apiErrorMessage(error), 3000);
  }
};

export const getOrgCustomerWalletPayoutEnabledCurrency = async (
  org_id,
  env
) => {
  try {
    let customer_payout_enabled_currency_list = [];
    const filters = [
      { filter_key: "environment", filter_value: env },
      { filter_key: "limit", filter_value: 1000 },
    ];

    const data = await Promise.all([
      getOrgControl(
        [
          ...filters,
          { filter_key: "key_type", filter_value: "CUSTOMER_WALLET_PAYOUT" },
        ],

        org_id,
        env
      ),
    ]);

    const settlement_data = data?.[0]?.data;

    settlement_data.forEach((currency) => {
      if (
        currency?.value !== null &&
        currency?.value?.is_enabled &&
        finmo_platform_supported_currency_list.includes(currency?.key)
      ) {
        customer_payout_enabled_currency_list.push(currency?.key);
      }
    });

    return {
      customer_payout_enabled_currency_list,
      env,
    };
  } catch (error) {
    toastNotification("error", apiErrorMessage(error), 3000);
  }
};

export const getEnabledPlugins = async (org_id, env) => {
  try {
    let plugins_list = [];

    const { data } = await getOrgControl(
      [
        { filter_key: "environment", filter_value: env },
        { filter_key: "limit", filter_value: 1000 },
        { filter_key: "key_type", filter_value: "PLUGIN" },
      ],
      org_id,
      env
    );

    data.forEach((plugin) => {
      plugins_list.push({ key: plugin?.key, value: plugin?.value });
    });

    return {
      plugins_list,
      env,
    };
  } catch (error) {
    toastNotification("error", apiErrorMessage(error), 3000);
  }
};

export const verifyDocSizeType = (e, fileSize = 2000000) => {
  if (
    e.target.files[0] &&
    (e.target.files[0].type === "application/pdf" ||
      e.target.files[0].type === "image/jpeg" ||
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpg")
  ) {
    if (Number(e.target.files[0].size) >= fileSize) {
      toastNotification(
        "error",
        `File size should not be more than ${
          fileSize === 2000000 ? "2MB" : `${fileSize / 1000000}MB`
        }`,
        2000
      );
      return false;
    }
  } else if (e.target.value !== "") {
    toastNotification(
      "error",
      "DOC format should be in PDF/JPEG/PNG/JPG",
      2000
    );
    return false;
  }

  return true;
};
export const toDecimal = (num, curr) => {
  const decimals =
    currency_decimal[curr] === 0
      ? 0
      : currency_decimal[curr]
      ? currency_decimal[curr]
      : 2;
  return parseFloat(num?.toFixed(decimals));
};
export const toCurrencyBasedDecimal = (num, curr) => {
  const format = decimal_format_list[currency_decimal[curr]];
  return numeral(toDecimal(num, curr)).format(format);
};

export const getCurrencyFlagPath = (currency) => {
  try {
    return require(`../assests/images/currencyflags/${currency.toLowerCase()}.png`)
      .default;
  } catch (err) {
    return null;
  }
};
export const getConnectorIconPath = (connector_name) => {
  try {
    return require(`../assests/images/connectorIcons/${connector_name}.svg`)
      .default;
  } catch (err) {
    return require(`../assests/images/connectorIcons/ocbc.svg`).default;
  }
};

export const GetAllQueryString = (remove_keys = []) => {
  const query = useQuery();
  let queryString = [];
  query.forEach((value, key) => {
    if (!remove_keys.includes(key)) {
      queryString.push({ filter_key: key, filter_value: value });
    }
  });
  return queryString;
};

export const formatTimeFilterData = (start_time, end_time) => {
  const dateFilter = [];

  if (start_time) {
    dateFilter.push({
      filter_key: "start_time",
      filter_value: Math.floor(start_time.getTime() / 1000),
    });
  }
  if (end_time) {
    dateFilter.push({
      filter_key: "end_time",
      filter_value: Math.floor(end_time.getTime() / 1000),
    });
  }

  return dateFilter;
};

export const convertArrayToObject = (array) => {
  const obj = {};
  array.forEach((item) => {
    obj[item.field_key] = item.field_value;
  });
  return obj;
};
