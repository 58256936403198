import { useContext, useEffect, useState } from "react";
import { Box, Hidden, IconButton, Tooltip } from "@mui/material";
import { experimentalStyled } from "@mui/material/styles";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import { SidebarContext } from "src/contexts/SidebarContext";
import HeaderUserbox from "./Userbox";
import RouteSearchBox from "./RouteSearchBox";
import useAuth from "src/hooks/useAuth";
import menuItems from "../Sidebar/SidebarMenu/items";

const HeaderWrapper = experimentalStyled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 5;
        background-color: ${theme.header.background};
        box-shadow: ${theme.header.boxShadow};
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`
);

function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const [routeSearchList, setRouteSearchList] = useState([]);
  const { user } = useAuth();

  useEffect(() => {
    const { user_type } = user;

    function getRoute(route, parentPath = "") {
      if (!route || typeof route !== "object") return [];
      const search_arr = [];
      if (
        route.name &&
        route.link !== undefined &&
        (!route.user_allowed || route.user_allowed.includes(user_type))
      ) {
        const fullPath = parentPath
          ? `${parentPath} > ${route.name}`
          : route.name;
        search_arr.push({ label: fullPath, link: route.link });
      }
      if (route.items && Array.isArray(route.items)) {
        route.items.forEach((item) => {
          search_arr.push(
            ...getRoute(
              item,
              parentPath ? `${parentPath} > ${route.name}` : route.name
            )
          );
        });
      }
      return search_arr;
    }

    const allRoutes = menuItems.flatMap((item) => getRoute(item));
    setRouteSearchList(allRoutes);
  }, [user]);

  return (
    <HeaderWrapper display="flex" alignItems="center">
      <Box display="flex" alignItems="center"></Box>
      <Box display="flex" alignItems="center">
        <Hidden mdDown>
          <RouteSearchBox
            suggested_route_list={routeSearchList.slice(1, 7)}
            all_route_list={routeSearchList}
          />
        </Hidden>
        <HeaderUserbox />
        <Hidden lgUp>
          <Tooltip arrow title="Search">
            <IconButton color="primary" onClick={toggleSidebar}>
              {!sidebarToggle ? <MenuTwoToneIcon /> : <CloseTwoToneIcon />}
            </IconButton>
          </Tooltip>
        </Hidden>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
