import { useRef, useState } from "react";
import useAuth from "src/hooks/useAuth";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";

import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import InboxTwoToneIcon from "@mui/icons-material/InboxTwoTone";
import { experimentalStyled } from "@mui/material/styles";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import AccountBoxTwoToneIcon from "@mui/icons-material/AccountBoxTwoTone";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
// import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import Label from "src/components/Label";
import PersonOutlineTwoToneIcon from "@mui/icons-material/PersonOutlineTwoTone";

const UserBoxButton = experimentalStyled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = experimentalStyled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxCompany = experimentalStyled(Box)(
  ({ theme }) => `
        background-color: ${theme.colors.primary.lighter};
        color: ${theme.colors.primary.main};
        padding: ${theme.spacing(1, 2)};
        border-radius: 8px
`
);

const UserBoxText = experimentalStyled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = experimentalStyled(Typography)(
  ({ theme }) => `
        font-weight: 500;
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = experimentalStyled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function HeaderUserbox() {
  const { t } = useTranslation();

  const history = useHistory();

  const { user, logout } = useAuth();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      history.push("/");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Hidden mdDown>
        <UserBoxText>
          {/* <UserBoxLabel sx={{ fontSize: '16px', mr: 1 }}>
            <UserBoxCompany>{user.email}</UserBoxCompany>
          </UserBoxLabel> */}
        </UserBoxText>
      </Hidden>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        {/* <Avatar variant="rounded" alt={user.first_name} src={user.avatar} /> */}

        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">{`${user.first_name} ${user.last_name}`}</UserBoxLabel>
          </UserBoxText>
        </Hidden>

        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>

      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          {/* <Avatar variant="rounded" alt={user.first_name} src={user.avatar} /> */}
          <UserBoxText>
            <UserBoxLabel variant="body1">{`${user.first_name} ${user.last_name}`}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user.jobtitle}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider />
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          <ListItem button to="/preview/dashboards/profile" component={NavLink}>
            <PersonOutlineTwoToneIcon fontSize="small" />
            <ListItemText primary={t("Profile")} />
          </ListItem>
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth onClick={handleLogout}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            {t("Sign out")}
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
